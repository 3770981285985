<script setup>
import BooksBannerBackground from '~/components/banners/BooksBannerBackground'

const props = defineProps({
  bannerType: {
    type: String,
    validator(value) {
      return ['static', 'video', 'books'].includes(value)
    },
    default: 'books',
  },
  canBuySubscription: {
    type: Boolean,
    default: true,
  },
  backgroundColor: {
    type: String,
    default: '#D3E5DE',
  },
  iconColor: {
    type: String,
    default: undefined,
  },
})

const config = useRuntimeConfig()
const videoSrc = `${config.public.AZURE_STORAGE_URL}/fe-assets/homepage-banner-video.mp4`
const src = `${config.public.IMAGE_RESIZE_URL}?image=fe-assets/homepage-banner.png`
const sources = `
    ${src}&width=750&height=752&fit=cover 1000w,
    ${src}&width=1440&height=661&fit=contain 1500w,
    ${src}&width=2880&height=1322&fit=pad 1800w`

const { $label } = useNuxtApp()
const btnLabel = computed(() => {
  return props.canBuySubscription
    ? $label('common.joinUs')
    : $label('page.home.startReadingBtn')
})

const router = useRouter()
function onBtnClick() {
  const link = props.canBuySubscription ? 'aanmelden' : 'boeken'

  router.push(link)
}
</script>

<template>
  <div
    :class="[
      'homepage-banner position-relative overflow-hidden',
      `homepage-banner--${bannerType}`,
    ]"
  >
    <div
      class="homepage-banner_img position-absolute w-100 h-100"
      :style="{ backgroundColor }"
    >
      <BooksBannerBackground
        v-if="bannerType === 'books'"
        :icon-color="iconColor"
      />

      <video v-else-if="bannerType === 'video'" autoplay loop muted playsinline>
        <source :src="videoSrc" type="video/mp4" />
      </video>

      <img v-else :src="src" :srcset="sources" width="1440" height="661" />
    </div>

    <div class="position-relative container">
      <div class="homepage-banner__content row position-relative">
        <slot name="content">
          <div class="col col-lg-6">
            <h1 class="mb-8 mb-md-16">
              {{ $label('page.home.headerTitle') }}
            </h1>
            <p class="mb-8 mb-md-16">
              {{ $label('page.home.headerSubtitle') }}
            </p>

            <BaseButton
              variant="call-to-action"
              class="become-member-btn mb-8 mb-md-16"
              @click="onBtnClick"
            >
              {{ btnLabel }}
            </BaseButton>

            <p class="mb-0 text-small fst-italic">
              {{ $label('page.home.headerCaption') }}
            </p>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.homepage-banner {
  color: $white;
  padding: 8% 0 28%;
}

.homepage-banner_img {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

img,
video {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.homepage-banner_img::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  background-image: linear-gradient(
    180deg,
    rgba(19, 19, 19, 0.28) 75.69%,
    rgba(0, 0, 0, 0) 95.76%
  );
}

h1 {
  color: $white;
}

.become-member-btn {
  width: 100%;
}

h1,
p {
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
}

.homepage-banner--books {
  text-align: center;
  padding: 18.5% 0 114%;

  .homepage-banner_img::after {
    background: rgba(20, 24, 26, 0.3);
    background-image: unset;
  }
  .homepage-banner__content {
    display: flex;
    justify-content: center;
  }
}

@include media-breakpoint-up(sm) {
  .homepage-banner--books {
    padding: 168px 0;
    .homepage-banner_img::after {
      background: rgba(20, 24, 26, 0.5);
    }
  }
}

@include media-breakpoint-up(md) {
  .homepage-banner {
    padding: 14% 0 17%;
  }

  .become-member-btn {
    width: 261px;
  }

  .homepage-banner--video,
  .homepage-banner--static {
    .homepage-banner_img::after {
      background: linear-gradient(
        83.17deg,
        rgba(19, 19, 19, 0.42) 39.46%,
        rgba(0, 0, 0, 0) 81.67%
      );
    }
  }
}

@include media-breakpoint-up(lg) {
  .homepage-banner--books {
    text-align: left;

    .homepage-banner_img::after {
      background: rgba(20, 24, 26, 0.2);
    }

    .homepage-banner__content {
      justify-content: flex-start;
      align-items: center;
    }
  }
}

@include media-breakpoint-up(xl) {
  .homepage-banner--books {
    padding: 266px 0;
  }
}
</style>
