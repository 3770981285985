<template>
  <div class="usp">
    <div class="row g-0 d-flex">
      <div
        cols="3"
        md="auto"
        class="col-3 col-md-auto d-flex justify-content-center"
      >
        <div
          class="usp__icon-background d-flex justify-content-center align-items-center"
          :style="{ backgroundColor: iconBackgroundColor }"
        >
          <slot name="icon" />
        </div>
      </div>

      <div class="col-12 col-sm-auto align-self-center d-md-flex d-none">
        <h4 class="mb-0 pl-24">
          {{ title }}
        </h4>
      </div>

      <div
        class="col-8 col-md-12 mt-md-24 d-flex justify-content-center flex-column"
      >
        <h4 class="mb-8 d-md-none">
          {{ title }}
        </h4>
        <p class="mb-0 text-small">
          {{ text }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    iconBackgroundColor: {
      type: String,
      default: '#F4F9FC',
    },
  },
}
</script>

<style lang="scss">
.usp__icon-background {
  width: 144px;
  height: 152px;
}

@include media-breakpoint-down(md) {
  .usp {
    justify-content: center;
    text-align: center;

    .row {
      justify-content: center;
    }
  }

  .usp__icon-background {
    margin-bottom: 24px;
  }
}

@include media-breakpoint-down(sm) {
  .usp {
    justify-content: flex-start;
    text-align: left;

    .row {
      justify-content: space-evenly;
    }

    .usp__icon {
      width: 21.33px;
      height: 24.38px;
    }
  }

  .usp__icon-background {
    width: 64px;
    height: 67.56px;
    margin-bottom: 0;
  }
}
</style>
