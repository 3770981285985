<template>
  <div class="container pt-56 pt-md-72 pb-32 pb-md-64">
    <div class="row justify-content-center">
      <div class="col-12 mb-32">
        <h2 class="text-center mb-0">
          {{ $label('page.usps.title') }}
        </h2>
      </div>

      <div class="col-12 col-md-10">
        <div class="usps-list row">
          <div
            v-for="usp in usps"
            :key="usp.key"
            class="usp__wrapper col-12 col-md-6 mb-24 mb-md-32"
          >
            <USP
              :title="$label(usp.title)"
              :text="$label(usp.text)"
              :icon-background-color="iconBackgroundColor"
            >
              <template #icon>
                <component
                  :is="usp.icon"
                  class="usp__icon fill-inherit"
                ></component>
              </template>
            </USP>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import USP from './USP'
import DevicesIcon from '~/assets/icons/devices.svg'
import ClosedBookIcon from '~/assets/icons/closed-book.svg'
import LoveBookIcon from '~/assets/icons/love-book.svg'
import EuroIcon from '~/assets/icons/euro.svg'
import USPPauseIcon from '~/assets/icons/usp-pause.svg'

export default {
  components: {
    USP,
    DevicesIcon,
    ClosedBookIcon,
    EuroIcon,
    LoveBookIcon,
    USPPauseIcon,
  },
  props: {
    iconBackgroundColor: {
      type: String,
      default: undefined,
    },
    usps: {
      type: Array,
      default: () => [
        {
          key: '1',
          title: 'page.usps.simpleTitle',
          text: 'page.usps.simpleText',
          icon: 'ClosedBookIcon',
        },
        {
          key: '2',
          title: 'page.usps.ownItTitle',
          text: 'page.usps.ownItText',
          icon: 'LoveBookIcon',
        },
        {
          key: '3',
          title: 'page.usps.everyMonthTitle',
          text: 'page.usps.everyMonthText',
          icon: 'EuroIcon',
        },
        {
          key: '4',
          title: 'page.usps.readDevicesTitle',
          text: 'page.usps.readDevicesText',
          icon: 'DevicesIcon',
        },
      ],
    },
  },
}
</script>
